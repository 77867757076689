import React, { useState, useRef } from "react";
import '../TermsUse.css'; // Importa o CSS

const PrivayPolicy = () => {

  return (
    <div className="terms-container">
      <h1>Política de Privacidade</h1>
      <p>Última atualização: 28 de setembro de 2024</p>

      <p>1. Introdução</p>
      <p>Sua privacidade é importante para nós. Esta Política de Privacidade descreve como coletamos, usamos, armazenamos e protegemos suas informações pessoais quando você utiliza nossa plataforma.</p>

      <p>2. Informações que Coletamos</p>
      <p>Coletamos as seguintes informações quando você utiliza nossa plataforma: <b>Informações de Cadastro:</b> Nome, data de início do relacionamento, mensagem personalizada, fotos do casal e endereço de email cadastrado. <b>Informações de Pagamento:</b> Endereço de email cadastrado no Mercado Pago para processamento do pagamento e envio do link da página personalizada.</p>

      <p>3. Como Usamos Suas Informações</p>
      <p>Utilizamos suas informações para: Processar o pagamento e enviar o link da página personalizada via email. Personalizar e criar a página do casal com as informações fornecidas. Melhorar nossos serviços e suporte ao cliente.</p>

      <p>4. Compartilhamento de Informações</p>
      <p>Não compartilhamos suas informações pessoais com terceiros, exceto conforme necessário para processar pagamentos (Mercado Pago) e conforme exigido por lei.</p>

      <p>5. Segurança</p>
      <p>Implementamos medidas de segurança para proteger suas informações pessoais contra acesso, uso ou divulgação não autorizados. No entanto, nenhuma transmissão de dados pela internet é completamente segura, e não podemos garantir a segurança absoluta.</p>

      <p>6. Retenção de Dados</p>
      <p>Reteremos suas informações pessoais apenas pelo tempo necessário para cumprir as finalidades para as quais foram coletadas ou conforme exigido por lei.</p>

      <p>7. Seus Direitos</p>
      <p>Você tem o direito de acessar, corrigir ou excluir suas informações pessoais. Para exercer esses direitos, entre em contato conosco pelo email: lucas.vrod@hotmail.com</p>

      <p>8. Alterações nesta Política de Privacidade</p>
      <p>Podemos atualizar esta Política de Privacidade periodicamente. Quando fizermos isso, revisaremos a data da "última atualização" no topo desta página. É sua responsabilidade revisar esta política periodicamente para se manter informado sobre quaisquer alterações.</p>

      <p>9. Contato</p>
      <p>Se você tiver alguma dúvida sobre esta Política de Privacidade, entre em contato conosco pelo email: lucas.vrod@hotmail.com</p>
    </div>
  );
};

export default PrivayPolicy;
