import React, { useEffect, useState } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase';
import '../SuccessPage.css'; // Importando o arquivo CSS para estilos

const SuccessPage = () => {
  const [data, setData] = useState(null);
  const [tempoJuntos, setTempoJuntos] = useState('');
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [player, setPlayer] = useState(null); // Armazena o player do YouTube
  const [isPlaying, setIsPlaying] = useState(false); // Estado para verificar se a música está tocando
  const params = new URLSearchParams(window.location.search);
  const casalURL = params.get('url');

  useEffect(() => {
    const fetchData = async () => {
      const docRef = doc(db, 'casais', casalURL);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setData(docSnap.data());
      } else {
        console.log("No such document!");
      }
    };

    fetchData();
  }, [casalURL]);

  useEffect(() => {
    if (data) {
      const calcularTempoJuntos = () => {
        const inicio = new Date(data.dataInicio);
        const agora = new Date();

        let diffMs = agora - inicio;

        const years = Math.floor(diffMs / (1000 * 60 * 60 * 24 * 365));
        diffMs -= years * (1000 * 60 * 60 * 24 * 365);

        const months = Math.floor(diffMs / (1000 * 60 * 60 * 24 * 30));
        diffMs -= months * (1000 * 60 * 60 * 24 * 30);

        const days = Math.floor(diffMs / (1000 * 60 * 60 * 24));
        diffMs -= days * (1000 * 60 * 60 * 24);

        const hours = Math.floor((diffMs / (1000 * 60 * 60)) % 24);
        diffMs -= hours * (1000 * 60 * 60);

        const minutes = Math.floor((diffMs / (1000 * 60)) % 60);
        const seconds = Math.floor((diffMs / 1000) % 60);

        setTempoJuntos(`${years} anos, ${months} meses, ${days} dias, ${hours} horas, ${minutes} minutos e ${seconds} segundos`);
      };

      calcularTempoJuntos();
      const intervalId = setInterval(calcularTempoJuntos, 1000);

      return () => clearInterval(intervalId);
    }
  }, [data]);

  useEffect(() => {
    // Faz a chuva de corações ocorrer continuamente
    const heartRainInterval = setInterval(() => {
      createHeartRain();
    }, 2000); // A cada 2 segundos (pode ajustar conforme necessário)
  
    return () => clearInterval(heartRainInterval); // Limpa o intervalo ao desmontar o componente
  }, []); // Sem dependências, para iniciar ao carregar a página

  useEffect(() => {
    if (data && data.uploadedImages && data.uploadedImages.length > 1) {
      const interval = setInterval(() => {
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % data.uploadedImages.length);
      }, 5000);
      return () => clearInterval(interval);
    }
  }, [data]);

  // Função para criar a chuva de corações
  const createHeartRain = () => {
    const container = document.querySelector('.success-container');
    for (let i = 0; i < 5; i++) { // Reduzindo para 5 corações por vez
      const heart = document.createElement('div');
      heart.classList.add('heart');
      heart.textContent = '❤️';
      heart.style.left = `${Math.random() * 100}vw`; // Posiciona o coração aleatoriamente na tela

      // Define uma rotação aleatória entre 0 e 20 graus
      const randomRotation = Math.random() * 20; 
      heart.style.setProperty('--rotate-deg', `${randomRotation}deg`);

      heart.style.animationDuration = `${Math.random() * 2 + 5}s`; // Duração aleatória da queda (5-7 segundos)
      container.appendChild(heart);
  
      // Remove o coração após 7 segundos
      setTimeout(() => {
        heart.remove();
      }, 7000); // Correspondente ao tempo da animação
    }
  };
  

  // Função chamada quando o usuário clica na imagem
  const handleImageClick = () => {
    if (player) {
      if (isPlaying) {
        player.pauseVideo(); // Pausa o vídeo se estiver tocando
      } else {
        player.unMute(); // Remove o mute
        player.playVideo(); // Toca o vídeo
      }
      setIsPlaying(!isPlaying); // Alterna o estado de reprodução
    }
  };

  // Carrega a API do YouTube e inicializa o player
  useEffect(() => {
    if (data && data.youtubeLink) {
      const youtubeId = new URL(data.youtubeLink).searchParams.get('v'); // Extrai o ID do vídeo
      const onYouTubeIframeAPIReady = () => {
        const youtubePlayer = new window.YT.Player('youtube-iframe', {
          videoId: youtubeId,
          playerVars: {
            autoplay: 0, // Não autoplay inicialmente
            mute: 1, // Começa mudo
            loop: 1,
            playlist: youtubeId, // Loop do vídeo
          },
          events: {
            onReady: (event) => {
              setPlayer(event.target); // Salva o player
            },
          },
        });
      };

      // Adiciona a API do YouTube ao carregar
      if (!window.YT) {
        const tag = document.createElement('script');
        tag.src = 'https://www.youtube.com/iframe_api';
        const firstScriptTag = document.getElementsByTagName('script')[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

        // Atribui a função para carregar o player
        window.onYouTubeIframeAPIReady = onYouTubeIframeAPIReady;
      } else {
        onYouTubeIframeAPIReady();
      }
    }
  }, [data]);

  if (!data) return <div>Loading...</div>;

  return (
    <div className="success-container">
      <div className="heart-container"></div>
      <h1 className="success-title">{data.nomeCasal}</h1>
      {data.uploadedImages && data.uploadedImages.length > 0 ? (
        <img 
          src={data.uploadedImages[currentImageIndex]} 
          alt={`${data.nomeCasal} Foto`} 
          className="success-image"
          onClick={handleImageClick} // Evento de clique na imagem para tocar ou pausar a música
          style={{ cursor: 'pointer' }} 
        />
      ) : (
        <div className="image-placeholder">Imagem não carregada</div>
      )}
      <p className="success-tempo">Juntos<br /> {tempoJuntos}</p>
      <hr className="divider" />
      <p className="success-message">{data.mensagem}</p>
      {data.youtubeLink && (
        <div className="music-container">
          <div id="youtube-iframe" style={{ display: 'none' }}></div> {/* Iframe invisível */}
        </div>
      )}
    </div>
  );
};

export default SuccessPage;
