import React, { useState, useEffect } from "react";
// import { loadStripe } from "@stripe/stripe-js";
import { ref, uploadString, getDownloadURL } from "firebase/storage";
import { doc, setDoc } from "firebase/firestore";
import { db, storage } from "./firebase";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"; // Importa o Router
import SuccessPage from "./components/SuccessPage"; // Componente da página de sucesso
import CongratsPage from './components/CongratsPage';
import ComoFunciona from './components/ComoFunciona';
import "./App.css";
import './styles.css';
import TermsUse from "./components/TermsUse";
import PrivayPolicy from "./components/PrivacyPolicy";
import vector from './images/pra_sempre_nos_logo.png';

// const stripePromise = loadStripe('pk_live_51Q2kLoGHdBkOrX9JAuqa6DBgaQYRelmQYaf3L0mJ54oXp0yVYpTEPBoGmb86FGu1TrVFNwXrZae4dZ7JoOT8T7wS00J4W45Cd6'); // Insira sua chave pública do Stripe

function App() {
  const [nomeDoCasal, setNomeDoCasal] = useState("");
  const [dataInicio, setDataInicio] = useState("");
  const [mensagem, setMensagem] = useState("");
  const [uploadedImages, setUploadedImages] = useState([]);
  const [tempoJuntos, setTempoJuntos] = useState("");
  const [plano, setPlano] = useState("plano2"); // Plano padrão
  const [maxPhotos, setMaxPhotos] = useState(10);
  const [isFormValid, setIsFormValid] = useState(false); // Controle de validação
  const [youtubeLink, setYoutubeLink] = useState("");
  const [showHint, setShowHint] = useState(false);
  const [cupom, setCupom] = useState("");
  const [desconto, setDesconto] = useState(0);
  const [mensagemCupom, setMensagemCupom] = useState("");

  // Função para validar o cupom
  const validarCupom = () => {
    if (cupom === "JORNALX" || cupom === "TABNEWS") {
      setDesconto(10); // 10% de desconto
      setMensagemCupom("Cupom aplicado com sucesso! Desconto de 10% adicionado."); // Mensagem de sucesso
    } else {
      setDesconto(0); // Cupom inválido
      setMensagemCupom("Cupom inválido! Tente novamente."); // Mensagem de erro
    }
  };

  useEffect(() => {
    // Atualiza o hint se o formulário não for válido
    if (!isFormValid) {
      setShowHint(true);
    } else {
      setShowHint(false);
    }
  }, [isFormValid]);

  useEffect(() => {
    // Atualiza o maxPhotos baseado no plano selecionado
    setMaxPhotos(plano === "plano1" ? 3 : 10);
  }, [plano]);

  // Limpa as fotos sempre que o plano for alterado
  useEffect(() => {
    setUploadedImages([]);
  }, [plano]);

  useEffect(() => {
    if (nomeDoCasal && dataInicio && mensagem && uploadedImages.length > 0) {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
  }, [nomeDoCasal, dataInicio, mensagem, uploadedImages]);

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);

    // Considera apenas as fotos permitidas pelo plano
    const limitedFiles = files.slice(0, maxPhotos);

    if (limitedFiles.length > 0) {
      const fileReaders = limitedFiles.map((file) => {
        return new Promise((resolve) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.readAsDataURL(file);
        });
      });

      Promise.all(fileReaders).then((results) => {
        setUploadedImages(results);
      });
    }
  };

  const calcularTempoJuntos = () => {
    if (!dataInicio) return;

    const inicio = new Date(dataInicio);
    const agora = new Date();

    let diffMs = agora - inicio;

    const years = Math.floor(diffMs / (1000 * 60 * 60 * 24 * 365));
    diffMs -= years * (1000 * 60 * 60 * 24 * 365);

    const months = Math.floor(diffMs / (1000 * 60 * 60 * 24 * 30));
    diffMs -= months * (1000 * 60 * 60 * 24 * 30);

    const days = Math.floor(diffMs / (1000 * 60 * 60 * 24));
    diffMs -= days * (1000 * 60 * 60 * 24);

    const hours = Math.floor((diffMs / (1000 * 60 * 60)) % 24);
    const minutes = Math.floor((diffMs / (1000 * 60)) % 60);
    const seconds = Math.floor((diffMs / 1000) % 60);

    setTempoJuntos(
      <>
        {years} anos{' '}
        {months} meses{' '}
        {days} dias<br />
        {hours} horas{' '}
        {minutes} minutos{' '}
        {seconds} segundos
      </>
    );
  };

  useEffect(() => {
    const intervalId = setInterval(calcularTempoJuntos, 1000);
    return () => clearInterval(intervalId);
  }, [dataInicio]);

  const createPageAndSaveToFirebase = async () => {
    const hash = crypto.randomUUID(); // Gerar hash único
    const casalURL = `${nomeDoCasal.replace(/\s+/g, '-')}-${hash}`;

    try {
      const uploadedImageUrls = await Promise.all(
        uploadedImages.map(async (image, index) => {
          // Gerar um nome único para cada imagem
          const imageName = `image_${index}_${crypto.randomUUID()}.jpg`;
          const imageRef = ref(storage, `imagensCasais/${casalURL}/${imageName}`);
          await uploadString(imageRef, image, 'data_url');
          return await getDownloadURL(imageRef);
        })
      );

      await setDoc(doc(db, 'casais', casalURL), {
        nomeCasal: nomeDoCasal,
        dataInicio: dataInicio,
        mensagem: mensagem,
        uploadedImages: uploadedImageUrls,
        planoSelecionado: plano,
        youtubeLink: youtubeLink || null,
        pagamentoStatus: 'pendente',
        url: casalURL,
      });

      console.log('Dados salvos com sucesso no Firebase:', casalURL);
      return casalURL;
    } catch (error) {
      console.error('Erro ao salvar no Firebase: ', error);
    }
  };

  const handleCheckout = async () => {
    const casalURL = await createPageAndSaveToFirebase();
    const mp = new window.MercadoPago('APP_USR-1258d059-ae98-4ff2-a2a5-77eac54d4aff', { locale: 'pt-BR' });

    var precoUnitario = plano === 'plano1' ? 14.9 : 29.9;
    if (desconto !== 0) {
      precoUnitario = precoUnitario - (precoUnitario * (desconto / 100));
    }

    const preference = {
      items: [
        {
          title: `Plano ${plano === 'plano1' ? '1 ano' : 'Pra sempre'}`,
          quantity: 1,
          unit_price: precoUnitario,
        },
      ],
      back_urls: {
        success: `${window.location.origin}/congrats/${casalURL}`,
        failure: `${window.location.origin}`,
        pending: `${window.location.origin}`,
      },
      auto_return: 'approved',
    };

    try {
      const response = await fetch('https://api.mercadopago.com/checkout/preferences', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer APP_USR-7238912471484510-092614-9cc8a963d02ff6c0dd4a31e5b2b0ad8f-211114556'
        },
        body: JSON.stringify(preference),
      });

      const preferenceData = await response.json();

      // Redirecionar o usuário para o checkout
      window.location.href = preferenceData.init_point;
    } catch (error) {
      console.error("Erro ao criar o checkout do Mercado Pago: ", error);
    }
  };

  // Lógica para alternar as fotos do preview a cada 5 segundos
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  useEffect(() => {
    if (uploadedImages.length > 1) {
      const interval = setInterval(() => {
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % uploadedImages.length);
      }, 5000);
      return () => clearInterval(interval);
    }
  }, [uploadedImages]);

  return (
    <Router>
      <Routes>
        <Route path="/success" element={<SuccessPage />} />
        <Route path="/congrats/:casalURL" element={<CongratsPage />} />
        <Route
          path="/"
          element={
            <>
              <header className="App-header">
                <img src={vector} alt="Logo" className="logo" />
              </header>
              <div className="container">
                <div className="form-section">
                  <p>Com apenas um clique crie um site personalizado para o seu amor, preencha as informações abaixo para criar sua página personalizada</p>

                  <div className="form-row">
                    <div className="form-field">
                      <label>Nome do casal:</label>
                      <input
                        type="text"
                        value={nomeDoCasal}
                        onChange={(e) => setNomeDoCasal(e.target.value)}
                        placeholder="Lucas e Margarida"
                        className="large-input" />
                    </div>
                    <div className="form-field">
                      <label>Data de início do relacionamento:</label>
                      <input
                        type="datetime-local"
                        value={dataInicio}
                        onChange={(e) => setDataInicio(e.target.value)}
                        className="small-input" />
                    </div>
                  </div>

                  <div className="form-field message-field">
                    <label>Mensagem:</label>
                    <textarea
                      value={mensagem}
                      onChange={(e) => setMensagem(e.target.value)}
                      placeholder="Deixe aqui sua declaração ❤️" />
                  </div>

                  <div className="form-field photo-field">
                    <input
                      type="file"
                      accept="image/*"
                      multiple
                      onChange={handleFileChange}
                      id="file-upload"
                      style={{ display: "none" }} />
                    <label htmlFor="file-upload" className="custom-file-upload">
                      <i className="fas fa-camera"></i>Escolher fotos do casal (Máximo: {maxPhotos})
                    </label>
                  </div>


                  <div className="form-row-plano">
                    <div className="form-field">
                      <div className="plano-selection">
                        <div
                          className={`plano-button ${plano === "plano1" ? "selected" : ""}`}
                          onClick={() => setPlano("plano1")}
                        >
                          1 ano, 3 fotos sem música - R$ 14,90
                        </div>
                      </div>
                    </div>
                    <div className="form-field">
                      <div
                        className={`plano-button ${plano === "plano2" ? "selected" : ""}`}
                        onClick={() => setPlano("plano2")}
                      >
                        Pra sempre, 10 fotos com música - R$ 29,90
                      </div>
                    </div>
                  </div>

                  <div className="form-row">
                    {plano === "plano2" && (
                      <div className="form-field">
                        <label>Música do YouTube (opcional):</label>
                        <input
                          type="text"
                          value={youtubeLink}
                          onChange={(e) => setYoutubeLink(e.target.value)}
                          placeholder="Link da música no YouTube"
                          className="large-input" />
                      </div>
                    )}
                  </div>
                  <div className="form-row-plano">
                    {/* Campo de entrada para o cupom */}
                    <div className="form-field">
                      <input
                        type="text"
                        placeholder="Digite o código do cupom"
                        value={cupom}
                        onChange={(e) => setCupom(e.target.value.toUpperCase())}
                        style={{ textTransform: 'uppercase' }}
                      />
                    </div>
                    <div className="form-field">
                      <button onClick={validarCupom} style={{ padding: '10px' }}>Aplicar Cupom</button>
                      {mensagemCupom && <p>{mensagemCupom}</p>} {/* Exibir mensagem do cupom */}
                    </div>
                  </div>
                </div>

                <div className="preview-section">
                  <div className="preview-container">
                    <div className="mac-window">
                      <div className="mac-header">
                        <i className="fas fa-arrow-left arrow"></i>
                        <i className="fas fa-arrow-right arrow"></i>
                        <i className="fas fa-light fa-rotate-right"></i>
                        <p className="custom-url">prasemprenos.com.br/{nomeDoCasal.toLowerCase().replace(/\s+/g, '-')}</p>
                      </div>
                      <div className="preview-body">
                        {uploadedImages.length > 0 ? (
                          <img src={uploadedImages[currentImageIndex]} alt="Preview do site" className="preview-image" />
                        ) : (
                          <div className="image-placeholder">
                          </div>
                        )}
                        <p className="tempo-juntos">
                          {dataInicio && "Juntos"}<br />{tempoJuntos}
                        </p>
                        <hr className="divider" />
                        <p className="mensagem-preview">{mensagem || "Mensagem de amor aqui!"}</p>
                      </div>
                    </div>
                    <button className="criar-button" onClick={handleCheckout} disabled={!isFormValid}>Criar Site</button>
                    {/* {showHint && <p className="hint">Preencha todos os campos para criar o site.</p>} */}
                  </div>
                </div>
              </div>
              <div className="footer">
                <ComoFunciona />
                <div className="footer-links">
                  <a href="/terms-of-use" className="footer-link">Termos de Uso </a>
                  <a href="/privacy-policy" className="footer-link">Política de Privacidade</a>
                </div>
              </div></>
          }
        />
        <Route path="/terms-of-use" element={<TermsUse />} />
        <Route path="/privacy-policy" element={<PrivayPolicy />} />
      </Routes>
    </Router>
  );
}

export default App;
