// Importando funções específicas do Firebase
import { getStorage } from "firebase/storage";
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

// Configuração do Firebase
const firebaseConfig = {
    apiKey: "AIzaSyBDVEatSrHe_M_bL0NC9Og8OvZaz_sRgoA",
    authDomain: "site-casal.firebaseapp.com",
    projectId: "site-casal",
    storageBucket: "site-casal.appspot.com",
    messagingSenderId: "709158831219",
    appId: "1:709158831219:web:cb29eb857dd1172031d606",
    measurementId: "G-13WYDKWS3T"
};

// Inicializando o app Firebase
const app = initializeApp(firebaseConfig);

// Obtendo a instância do Firestore
const db = getFirestore(app);

// Inicialize o Storage
const storage = getStorage(app);

// Exportando a instância do Firestore
export { db, storage };
