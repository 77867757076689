// ComoFunciona.js
import React from 'react';
import '../ComoFunciona.css'; // Você pode criar esse arquivo para estilização
import comoFunciona from '../images/como_funciona.png';
import heart from '../images/heart.png';

const ComoFunciona = () => {
  return (
    <section className="como-funciona-section">
      <img src={comoFunciona} alt="Como funciona" className="como-funciona" />
      <div className="steps-container">
        <div className="step-box">
          <img src={heart} alt="Preencha os dados" className="heart-logo" />
          <p>Preencha os dados </p>
        </div>
        <div className="step-box">
          <img src={heart} alt="2. Faça o pagamento" className="heart-logo" />
          <p>Faça o pagamento</p>
        </div>
        <div className="step-box">
          <img src={heart} alt="Receba o seu site + QR Code no e-mail" className="heart-logo" />
          <p>Receba o seu site + QR Code no e-mail</p>
        </div>
        <div className="step-box">
          <img src={heart} alt="Faça uma surpresa para o seu amor" className="heart-logo" />
          <p>Faça uma surpresa para o seu amor</p>
        </div>
      </div>
    </section>
  );
};

export default ComoFunciona;
