import React, { useState, useRef } from "react";
import emailjs from "emailjs-com";
import { QRCodeCanvas } from "qrcode.react"; // Importa o QRCodeCanvas
import { doc, updateDoc } from "firebase/firestore"; // Importa a função para atualizar o documento no Firestore
import { db } from "../firebase"; // Importa o Firebase Firestore
import '../CongratsPage.css'; // Importa o CSS

const CongratsPage = ({ casalURL }) => {
  const [email, setEmail] = useState("");
  const [emailSent, setEmailSent] = useState(false);
  const qrRef = useRef(); // Ref para o QRCodeCanvas

  // Captura a URL atual e faz o split para pegar a parte antes do '?'
  const currentUrl = window.location.href.split("?")[0];

  // Constrói a nova URL
  const casalUrl = currentUrl.replace("congrats/", "success?url=");

  const sendEmail = async (e) => {
    e.preventDefault();
    // Converter o QR Code em Base64
    const qrCodeDataUrl = qrRef.current.toDataURL("image/png");

    const templateParams = {
      casal_url: casalUrl, // Link do casal
      qr_code: qrCodeDataUrl, // URL do QR code em Base64
      to_email: email,
    };

    try {
      await emailjs.send("service_e6o2hrt", "template_pjkprbk", templateParams, "q8hKbzwf_UScxa7h0");
      
      // Atualiza o Firestore com o e-mail do usuário
      const casalDocRef = doc(db, "casais", casalUrl.split("=")[1]); // Referência ao documento do casal
      await updateDoc(casalDocRef, { email: email });

      setEmailSent(true);
    } catch (error) {
      console.error("Erro ao enviar email:", error);
    }
  };

  return (
    <div className="congrats-container">
      <h1>Parabéns pela compra!</h1>
      <p>Insira seu email para receber o QR code e o link do seu site:</p>
      <form onSubmit={sendEmail}>
        <input
          type="email"
          placeholder="Seu email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <button type="submit">Enviar</button>
      </form>
      {emailSent && <p>Email enviado com sucesso!</p>}

      <div className="qr-code-container">
        {/* Gerar e exibir o QR code com o URL do casal */}
        <QRCodeCanvas ref={qrRef} value={casalUrl} />
      </div>
    </div>
  );
};

export default CongratsPage;
