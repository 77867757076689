import React, { useState, useRef } from "react";
import '../TermsUse.css'; // Importa o CSS

const TermsUse = () => {

  return (
    <div className="terms-container">
      <h1>Termos de uso</h1>
      <p>Última atualização: 28 de setembro de 2024</p>

      <p>1. Aceitação dos Termos</p>
      <p>Ao acessar e utilizar a nossa plataforma, você concorda em cumprir e ficar vinculado aos seguintes Termos de Uso. Caso não concorde com qualquer parte destes termos, você não deve utilizar a plataforma.</p>

      <p>2. Descrição do Serviço</p>
      <p>Nossa plataforma permite que casais criem uma página personalizada preenchendo um formulário com seu nome, data de início do relacionamento, uma mensagem personalizada e até 10 fotos. Após o preenchimento, o casal é direcionado para o checkout e, ao concluir o pagamento, recebe um link com um QR Code via email.</p>

      <p>3. Cadastro e Segurança</p>
      <p>Para utilizar o serviço, você deve fornecer um endereço de email válido. Não compartilharemos seu email com terceiros.</p>

      <p>4. Privacidade</p>
      <p>Respeitamos a sua privacidade. Não utilizamos seus dados para qualquer tipo de processamento ou venda de dados para terceiros. O email cadastrado é utilizado apenas para o envio do link da página personalizada.</p>

      <p>5. Conteúdo do Usuário</p>
      <p>Você é responsável pelo conteúdo que insere na plataforma, incluindo fotos, mensagens e informações do relacionamento. Não nos responsabilizamos por qualquer conteúdo impróprio ou ilegal carregado pelos usuários.</p>

      <p>6. Pagamentos e Reembolsos</p>
      <p>Todos os pagamentos são processados através do Mercado Pago. Após a conclusão do pagamento, o casal receberá um link para a página personalizada via email. Não oferecemos reembolsos, exceto em casos excepcionais a nosso exclusivo critério.</p>

      <p>7. Modificações no Serviço</p>
      <p>Nós nos comprometemos a manter o serviço ativo e disponível pelo período contratado, conforme o plano escolhido (1 ano no plano básico ou tempo vitalício no plano avançado). No entanto, em circunstâncias excepcionais que fujam ao nosso controle, como questões legais, técnicas ou financeiras, reservamo-nos o direito de modificar ou descontinuar o serviço. Caso seja necessário descontinuar o serviço, tomaremos todas as medidas possíveis para notificar os usuários com antecedência e garantir a preservação das páginas ou oferecer soluções alternativas sempre que possível. A empresa não se responsabiliza por eventuais perdas decorrentes de modificações ou descontinuação em situações extraordinárias, mas faremos o possível para minimizar o impacto.</p>

      <p>8. Limitação de Responsabilidade</p>
      <p>Em nenhuma circunstância seremos responsáveis por qualquer dano indireto, incidental, especial ou consequente decorrente de ou relacionado ao uso ou incapacidade de uso da plataforma.</p>

      <p>9. Alterações nos Termos</p>
      <p>Podemos atualizar estes Termos de Uso periodicamente. Quando fizermos isso, revisaremos a data da "última atualização" no topo desta página. É sua responsabilidade revisar estes Termos de Uso periodicamente para se manter informado sobre quaisquer alterações.</p>

      <p>10. Contato</p>
      <p>Se você tiver alguma dúvida sobre estes Termos de uso, entre em contato conosco pelo email: lucas.vrod@hotmail.com</p>
    </div>
  );
};

export default TermsUse;
